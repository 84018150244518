import $ from 'jquery';

window.$ = window.jQuery = $;

import './hamburger';
import './gallery';

document
    .querySelector(".hamburger")
    .addEventListener("click", () => {
        document.querySelector(".hamburger").classList.toggle("hamburger--open");
        document
            .querySelector(".nav")
            .classList.toggle("nav--active");
    });


// Carousel
import Splide from '@splidejs/splide';
import '@splidejs/splide/css';

new Splide('.splide', {
    type: 'loop',
    autoplay: true
}).mount();
